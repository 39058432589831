<template>
    <div>
        <div class="header px-5 py-3">
            <div class="d-flex">
                <h1 class="fw-normal mb-0 fs-30" style="color:#08aee9;">Digicollect</h1>
                <img style="margin-left:10px" src="/static/images/Digicollect Logo@3x.png" width="40" height="35"
                    alt="">
            </div>
            <span class="fs-14 fw-normal">Intelligent Industrial Internet</span>
        </div>
        <div class="banner">
            <img src="/static/images/web-portal-banner.png" class="w-100" alt="">
        </div>
        <div class="px-5">
            <h1 class="text-center fs-30 fw-normal my-4" style="color:#206ad3">Survey Form</h1>
            <h3 class="fs-20 py-2 fw-normal">Survey Brief</h3>
            <div class="d-flex">
                <span class="fw-normal fs-13">Description - Lorem Ipsum is a dummy text used in web to fill place</span>
                <span class="fw-normal ml-3 fs-13">Fill to duras Lorem Ipsum is a dummy text used in web to fill
                    place</span>
            </div>
            <div class="d-flex">
                <span class="fw-normal fs-13">Description - Lorem Ipsum is a dummy text used in web to fill place</span>
                <span class="fw-normal ml-3 fs-13">Fill to duras Lorem Ipsum is a dummy text used in web to fill
                    place</span>
            </div>
            <div class="d-flex">
                <span class="fw-normal fs-13">Description - Lorem Ipsum is a dummy text used in web to fill place</span>
                <span class="fw-normal ml-3 fs-13">Fill to duras Lorem Ipsum is a dummy text used in web to fill
                    place</span>
            </div>
        </div>
        <div class="px-5">
            <h3 style="color:#4e85f2" class="fs-17 fw-normal pt-4">Survey Questions</h3>
            <div>
                <div class="questionnaire-box mt-4">
                    <span class="input-label pl-0">Question 1</span>
                    <p class="fs-14 color-gray pt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
                        dictum convallis felis, quis consectetur elit feugiat a. Vestibulum erat lacus, lobortis in
                        tincidunt quis, lobortis sit </p>
                    <input type="text" placeholder="Short Answer" class="input-border-bottom" v-model="short_answer" />
                </div>
                <div class="questionnaire-box mt-4">
                    <span class="input-label pl-0">Question 2</span>
                    <p class="fs-14 color-gray pt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Vestibulum dictum convallis felis, quis consectetur elit feugiat a.
                        Vestibulum erat lacus, lobortis in tincidunt quis, lobortis sit </p>
                    <input type="text" placeholder="Long Answer" class="input-border-bottom" v-model="long_answer" />
                    <input type="text" placeholder="" class="input-border-bottom" v-model="long_answer" />
                </div>
                <div class="questionnaire-box mt-4">
                    <span class="input-label pl-0">Question 3</span>
                    <p class="fs-14 color-gray pt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Vestibulum dictum convallis felis, quis consectetur elit feugiat a.
                        Vestibulum erat lacus, lobortis in tincidunt quis, lobortis sit </p>
                    <div class="d-flex align-items-end mt-3">
                        <div class="form-check form-check-inline mx-0">
                            <label class="form-check-label check-label-dc">
                                <input class="form-check-input" type="radio" value="" v-model="long_answer_radio" />
                                <span class="radio-icon"></span>
                            </label>
                        </div>
                        <input type="text" placeholder="Option 1" class="input-border-bottom pb-0"
                            style="width: 200px !important;" v-model="long_answer" />
                    </div>
                    <div class="d-flex align-items-end mt-3">
                        <div class="form-check form-check-inline mx-0">
                            <label class="form-check-label check-label-dc">
                                <input class="form-check-input" type="radio" value="" v-model="long_answer_radio" />
                                <span class="radio-icon"></span>
                            </label>
                        </div>
                        <input type="text" placeholder="Option 2" class="input-border-bottom pb-0"
                            style="width: 200px !important;" v-model="long_answer" />
                    </div>
                </div>
                <div class="questionnaire-box mt-4">
                    <span class="input-label pl-0">Question 4</span>
                    <p class="fs-14 color-gray pt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Vestibulum dictum convallis felis, quis consectetur elit feugiat a.
                        Vestibulum erat lacus, lobortis in tincidunt quis, lobortis sit </p>
                    <div class="d-flex align-items-end mt-3">
                        <el-checkbox v-model="long_answer_checkbox" style="margin-right:10px;" class="mb-0 mr-2">
                        </el-checkbox>
                        <input type="text" placeholder="Option 1" class="input-border-bottom pb-0"
                            style="width: 200px !important;" v-model="long_answer" />
                    </div>
                    <div class="d-flex align-items-end mt-3">
                        <el-checkbox v-model="long_answer_checkbox" style="margin-right:10px;" class="mb-0 mr-2">
                        </el-checkbox>
                        <input type="text" placeholder="Option 2" class="input-border-bottom pb-0"
                            style="width: 200px !important;" v-model="long_answer" />
                    </div>
                </div>
                <div class="questionnaire-box mt-4">
                    <span class="input-label pl-0">Question 5</span>
                    <p class="fs-14 color-gray pt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Vestibulum dictum convallis felis, quis consectetur elit feugiat a.
                        Vestibulum erat lacus, lobortis in tincidunt quis, lobortis sit </p>
                    <div class="mt-3">
                        <input type="text" placeholder="Option 1" class="input-border-bottom pb-0"
                            style="width: 200px !important;" v-model="long_answer" />
                    </div>
                    <div class="mt-3">
                        <input type="text" placeholder="Option 2" class="input-border-bottom pb-0"
                            style="width: 200px !important;" v-model="long_answer" />
                    </div>
                </div>
                <div class="questionnaire-box mt-4">
                    <span class="input-label pl-0">Question 6</span>
                    <p class="fs-14 color-gray pt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Vestibulum dictum convallis felis, quis consectetur elit feugiat a.
                        Vestibulum erat lacus, lobortis in tincidunt quis, lobortis sit </p>
                    <div class="mt-3">
                        <i class="icon icon-star icon-orange fs-30"></i>
                        <i class="icon icon-star icon-orange fs-30"></i>
                        <i class="icon icon-star-outline icon-orange fs-30"></i>
                        <i class="icon icon-star-outline icon-orange fs-30"></i>
                        <i class="icon icon-star-outline icon-orange fs-30"></i>
                    </div>
                </div>
                <div class="questionnaire-box mt-4">
                    <span class="input-label pl-0">Question 7</span>
                    <p class="fs-14 color-gray pt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Vestibulum dictum convallis felis, quis consectetur elit feugiat a.
                        Vestibulum erat lacus, lobortis in tincidunt quis, lobortis sit </p>
                    <div class="row align-items-center">
                        <div class="col-lg-3 col-md-3 col-sm-6">
                            <div class="mt-3">
                                <input type="text" placeholder="Option 1" class="input-border-bottom pb-0"
                                    style="width: 200px !important;" v-model="long_answer" />
                            </div>
                            <div class="mt-3">
                                <input type="text" placeholder="Option 2" class="input-border-bottom pb-0"
                                    style="width: 200px !important;" v-model="long_answer" />
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6  ml-5 ml-sm-10">
                            <div class="d-flex align-items-end mt-3">
                                <div class="form-check form-check-inline mr-0">
                                    <label class="form-check-label check-label-dc">
                                        <input class="form-check-input" type="radio" value=""
                                            v-model="long_answer_radio" />
                                        <span class="radio-icon"></span>
                                    </label>
                                </div>
                                <input type="text" placeholder="Option 1" class="input-border-bottom pb-0"
                                    style="width: 200px !important;" v-model="long_answer" />
                            </div>
                            <div class="d-flex align-items-end mt-3">
                                <div class="form-check form-check-inline mr-0">
                                    <label class="form-check-label check-label-dc">
                                        <input class="form-check-input" type="radio" value=""
                                            v-model="long_answer_radio" />
                                        <span class="radio-icon"></span>
                                    </label>
                                </div>
                                <input type="text" placeholder="Option 2" class="input-border-bottom pb-0"
                                    style="width: 200px !important;" v-model="long_answer" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="questionnaire-box mt-4">
                    <span class="input-label pl-0">Question 8</span>
                    <p class="fs-14 color-gray pt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Vestibulum dictum convallis felis, quis consectetur elit feugiat a.
                        Vestibulum erat lacus, lobortis in tincidunt quis, lobortis sit </p>
                    <div class="row align-items-center">
                        <div class="col-lg-3 col-md-3 col-sm-6">
                            <div class="mt-3">
                                <input type="text" placeholder="Option 1" class="input-border-bottom pb-0"
                                    style="width: 200px !important;" v-model="long_answer" />
                            </div>
                            <div class="mt-3">
                                <input type="text" placeholder="Option 2" class="input-border-bottom pb-0"
                                    style="width: 200px !important;" v-model="long_answer" />
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 ml-5 ml-sm-10">
                            <div class="d-flex align-items-end mt-3">
                                <el-checkbox v-model="long_answer_checkbox" class="mb-0 mr-2"></el-checkbox>
                                <input type="text" placeholder="Option 1" class="input-border-bottom pb-0"
                                    style="width: 200px !important;" v-model="long_answer" />
                            </div>
                            <div class="d-flex align-items-end mt-3">
                                <el-checkbox v-model="long_answer_checkbox" class="mb-0 mr-2"></el-checkbox>
                                <input type="text" placeholder="Option 2" class="input-border-bottom pb-0"
                                    style="width: 200px !important;" v-model="long_answer" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="questionnaire-box mt-4">
                    <span class="input-label pl-0">Question 9</span>
                    <p class="fs-14 color-gray pt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Vestibulum dictum convallis felis, quis consectetur elit feugiat a.
                        Vestibulum erat lacus, lobortis in tincidunt quis, lobortis sit </p>
                    <div class="d-flex align-items-center mt-3">
                        <date-picker class="new-date-picker input-field-underline pr-0" placeholder="DD/MM/YYYY"
                            v-model="closure_date" value-type="format" style="width: 150px !important;" lang="en"
                            :not-before="new Date()" type="date" format="DD/MM/YYYY">
                        </date-picker>
                    </div>
                </div>
                <div class="questionnaire-box mt-4">
                    <span class="input-label pl-0">Question 10</span>
                    <p class="fs-14 color-gray pt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Vestibulum dictum convallis felis, quis consectetur elit feugiat a.
                        Vestibulum erat lacus, lobortis in tincidunt quis, lobortis sit </p>
                    <div class="d-flex align-items-center mt-3">
                        <date-picker class="new-time-picker input-field-underline" v-model="question_time"
                            placeholder="--:--" value-type="format"
                            style="width: 150px !important; padding-right: 2px !important;" lang="en"
                            :not-before="new Date()" type="time" format="HH/MM">
                        </date-picker>
                    </div>
                </div>
            </div>
            <div class="py-5">
                <div class="d-flex justify-content-center">
                    <button @click="OpenSubmitFailedModal()" type="button" style="border-radius:0px;"
                        class="btn btn-outline-primary px-4 text-uppercase">Clear</button>
                    <button @click="OpenSubmitSuccessModal()" type="button" style="border-radius:0px;"
                        class="btn btn-primary ml-3 px-4 text-uppercase">Submit</button>
                </div>
            </div>
        </div>
        <submitted-successfully-modal @hide-submit-successful-modal="hideSubmitSuccessfulModal"
            modal_name="Submitted-Successfully-Modal" v-if="submit_success"></submitted-successfully-modal>
        <submission-failed-modal @hide-submit-failed-modal="hideSubmissionFailedModal" 
            modal_name="Submission-Failed-Modal" v-if="submit_failed"></submission-failed-modal>
    </div>
</template>

<script>
    const SubmittedSuccessfullyModal = () => import('./SubmittedSuccessfullyModal');
    const SubmissionFailedModal = () => import('./SubmissionFailedModal');
    export default ({
        data() {
            return {
                submit_success: false,
                submit_failed: false,
            }
        },
        methods: {
            OpenSubmitSuccessModal() {
                this.submit_success = true
                setTimeout(() => {
                    this.$modal.show('Submitted-Successfully-Modal')
                }, 500);
            },
            hideSubmitSuccessfulModal() {
                this.submit_success = false
            },
            OpenSubmitFailedModal(){
                this.submit_failed = true
                setTimeout(() => {
                    this.$modal.show('Submission-Failed-Modal')
                })
            },
            hideSubmissionFailedModal(){
                this.submit_failed = false
            }
        },
        components: {
            SubmittedSuccessfullyModal,
            SubmissionFailedModal
        }
    })
</script>

<style scoped>
    .icon-orange {
        color: #ff9a06;
    }

    .questionnaire-box {
        padding: 16.1px 34.2px 15.8px 20px;
        border-radius: 3px;
        border: solid 0.5px rgba(108, 109, 111, 0.3);
        background-color: #fff;
    }

    .input-label {
        font-weight: 500;
        line-height: 1.5;
        letter-spacing: 0.39px;
        text-align: left;
        color: #0475f3;
        padding: 0 0 6px 10px;
        font-size: 14px;
    }

    .color-gray {
        color: #3f3f3f;
    }

    .input-border-bottom {
        border: 0 !important;
        padding: 5px 8px;
        padding-left: 0;
        width: 100% !important;
        border-bottom: solid 1px #707070 !important;
    }

    .input-field-underline {
        border: 0 !important;
        border-bottom: solid 0.5px #3f3f3f !important;
        background-color: #fff;
        padding: 0 !important;
        width: 100% !important;
        /* height: 40px; */
    }

    @media screen and (max-width:768px){
        .ml-sm-10{
            margin-left: 0px !important;
        }
    }
</style>